<template>
    <div class="section mt-1">
        <ul class="nav nav-tabs capsuled" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                    @click="goToTab('Home')">
                    <ion-icon name="home-outline" role="img" class="md hydrated" aria-label="home-outline"></ion-icon>
                    Home
                </a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                    @click="goToTab('Upcoming')">
                    <ion-icon name="list-outline" role="img" class="md hydrated" aria-label="home-outline"></ion-icon>
                    Upcomings
                </a>
            </li> -->
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('Old')">
                    <ion-icon name="timer-outline" role="img" class="md hydrated" aria-label="home-outline"></ion-icon>
                    Old
                </a>
            </li>
        </ul>
    </div>
    <div class="section mt-1">
        <div class="section text-center mt-1" v-if="loading">
            <div class="spinner-border text-secondary" role="status"></div>
        </div>
        <div class="listed-detail mt-3" v-if="responseData.list.length == 0 && !loading">
            <h3 class="text-center mt-2 text-primary">No Data
            </h3>
        </div>

        <!-- Home -->
        <div class="goals" v-if="tabHome && !loading">
            <!-- item -->
            <router-link :to="'/org/'+user_level+'/'+user_level_id+'/meeting-report'" v-if="user_level <= 3">
                <button type="button" class="report-button">
                    <span>മീറ്റിംഗ് റിപ്പോർട്ട് കാണാൻ ക്ലിക്ക് ചെയ്യുക</span>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
            </router-link>
            <div class="meeting-grid">
                <div class="meeting-card" v-for="todaysMeeting in responseData.list" v-bind:key="todaysMeeting.id">
                    <router-link :to="'meeting/' + todaysMeeting.meeting_id" class="meeting-link">
                        <div class="in">
                            <div>
                                <h3 class="meeting-title"><span>{{ todaysMeeting.meeting.meeting_url }}</span> &nbsp;{{ todaysMeeting.meeting.meeting_name_mal }}</h3>
                                <p class="meeting-comment"><span v-if="todaysMeeting.meeting.meeting_period">Meeting Period: {{
                                        todaysMeeting.meeting.meeting_period
                                    }},</span>{{ todaysMeeting.comments }}
                                </p>
                            </div>
                            <span class="status-badge price mal-text">
                                <ion-icon name="checkmark-circle-outline" class="price-icon" title="നടന്നു"></ion-icon>
                            </span>
                        </div>
                        <hr>
                        <div class="meeting-details">
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="calendar-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Date</span>
                                    <span class="detail-value">
                                        {{
                                            todaysMeeting.meeting.meeting_date ? todaysMeeting.meeting.meeting_date :
                                                '--/--/----'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="time-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Time</span>
                                    <span class="detail-value">
                                        {{
                                            todaysMeeting.meeting.meeting_time ? todaysMeeting.meeting.meeting_time :
                                                '--:--'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="location-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Location</span>
                                    <span class="detail-value">
                                        {{
                                            todaysMeeting.meeting.meeting_location ?
                                            todaysMeeting.meeting.meeting_location :
                                             '---'
                                        }}
                                    </span> 
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="people-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Participants</span>
                                    <span class="detail-value">
                                        {{
                                            todaysMeeting.participants_count ? todaysMeeting.participants_count :
                                                '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="status-footer">
                            <span class="status-box">
                                <span class="status-text">Status:{{}}</span>
                            </span>
                        </div>
                    </router-link>
                     <!-- <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">85%</div>
                    </div> -->
                </div>
            </div>
            <!-- * item -->
        </div>

        <!-- Upcomings -->
        <div class="goals" v-if="tabUpcoming && !loading">
            <!-- item -->
            <div class="meeting-grid">
                <div class="meeting-card" v-for="upcomingMeeting in responseData.list" v-bind:key="upcomingMeeting.id">
                    <router-link :to="'meeting/' + upcomingMeeting.meeting_id" class="meeting-link">
                        <div class="in">
                            <div>
                                <h3 class="meeting-title"><span>{{ upcomingMeeting.meeting.meeting_url }}</span> &nbsp;{{ upcomingMeeting.meeting.meeting_name_mal }}</h3>
                                <p class="meeting-comment"><span v-if="upcomingMeeting.meeting.meeting_period">Meeting Period: {{
                                        upcomingMeeting.meeting.meeting_period
                                    }},</span>{{ upcomingMeeting.comments }}
                                </p>
                            </div>
                            <span class="status-badge price mal-text">
                                <ion-icon name="checkmark-circle-outline" class="price-icon" title="നടന്നു"></ion-icon>
                            </span>
                        </div>
                        <hr>
                        <div class="meeting-details">
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="calendar-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Date</span>
                                    <span class="detail-value">
                                        {{
                                            upcomingMeeting.meeting.meeting_date ? upcomingMeeting.meeting.meeting_date :
                                                '--/--/----'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="time-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Time</span>
                                    <span class="detail-value">
                                        {{
                                             upcomingMeeting.meeting.meeting_time ? upcomingMeeting.meeting.meeting_time :
                                                '--:--'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="location-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Location</span>
                                    <span class="detail-value">
                                        {{
                                            upcomingMeeting.meeting.meeting_location ?
                                            upcomingMeeting.meeting.meeting_location :
                                                '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="people-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Participants</span>
                                    <span class="detail-value">
                                        {{
                                            upcomingMeeting.participants_count ? upcomingMeeting.participants_count :
                                                '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="status-footer">
                            <span class="status-box">
                                <span class="status-text">Status:{{}}</span>
                            </span>
                        </div>
                    </router-link>
                     <!-- <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">85%</div>
                    </div> -->
                </div>
            </div>
            <!-- * item -->
        </div>

        <!-- Old -->
        <div class="goals" v-if="tabOld && !loading">
            <!-- item -->
            <div class="meeting-grid">
                <div class="meeting-card" v-for="oldMeeting in responseData.list" :key="oldMeeting.id">
                    <router-link :to="'meeting/' + oldMeeting.meeting_id" class="meeting-link">
                        <div class="in">
                            <div>
                                <h3 class="meeting-title">{{ oldMeeting.meeting.meeting_name_mal }}</h3>
                                <p class="meeting-comment">{{ oldMeeting.comments }}</p>
                            </div>
                            <span class="status-badge price mal-text">
                                <ion-icon name="checkmark-circle-outline" class="price-icon" title="നടന്നു"></ion-icon>
                            </span>
                        </div>
                        <hr>
                        <div class="meeting-details">
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="calendar-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Date</span>
                                    <span class="detail-value">
                                        {{
                                            oldMeeting.meeting.meeting_date ? oldMeeting.meeting.meeting_date :
                                                '--/--/----'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="time-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Time</span>
                                    <span class="detail-value">
                                        {{
                                             oldMeeting.meeting.meeting_time ? oldMeeting.meeting.meeting_time :
                                                '--:--'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="location-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Location</span>
                                    <span class="detail-value">
                                        {{
                                             oldMeeting.meeting.meeting_location ? oldMeeting.meeting.meeting_location :
                                                '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="people-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Participants</span>
                                    <span class="detail-value">
                                        {{
                                            oldMeeting.participants_count ? oldMeeting.participants_count :
                                                '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="status-footer">
                            <span class="status-box">
                                <span class="status-text">Status:{{}}</span>
                            </span>
                        </div>
                    </router-link>
                     <!-- <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">85%</div>
                    </div> -->
                </div>
            </div>
            <!-- * item -->
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
// import { Form, Field } from "vee-validate";
import UserService from "../../services/user.service";
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import $ from 'jquery';
export default {
    name: 'Organization',
    components: {
        // Form,
        // Field,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            tabHome: true,
            tabUpcoming: false,
            tabOld: false,
            responseData: {
                meeting: [],
                participants: [],
                agenda: [],
                minutes: [],
                list: []
            },

            message: "",
            user_level: "",
            permissions: [],
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',

            sub_level: [],
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            this.$root.updateParent("Loading...");
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            }
            else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            }
            else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getMeetings('Home');
        }
    },
    methods: {
        goToTab(tab) {
            this.loading = true;
            this.tabHome = false;
            this.tabUpcoming = false;
            this.tabOld = false;
            if (tab == 'Home') {
                this.tabHome = true;
                this.getMeetings('Home');
            } else if (tab == 'Upcoming') {
                this.tabUpcoming = true;
                this.getMeetings('Upcoming');
            } else {
                this.tabOld = true;
                this.getMeetings('Old');
            }
        },

        getMeetings(type) {
            this.loading = true;
            let data = {
                type: type
            }
            UserService.authPostRequest('get-meetings', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.responseData = response.data.data;
                        this.$root.updateParent('എന്റെ മീറ്റിംഗുകൾ');
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/level-2-org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        addNerpathamTarget(data) {
            data.level = this.currentUser.user_level + 1;
            data.level_id = parseInt(this.sub_level_id);
            UserService.addAdmin('set-nerpatham-target', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getSubLevels();
                        $('#nerpathamTargetModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}


.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}


.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}

.goals .item {
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    margin-bottom: 10px;
}

.price-icon {
    /* margin-top: -1%; */
    font-size: 24px;
}

.chip-label[data-v-638c6bb6] {
    padding: 0px 5px 0px 5px !important;
    font-size: 14px !important;
}

.pad-5-left {
    padding-left: 5px;
}

.meeting-grid {
    display: grid;
    gap: 15px;
}

.meeting-card {
    background-color: #ffffff;
    border-radius: 8px;
}

.meeting-link {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 20px;
}

.in {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meeting-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #333333;
}

.meeting-comment {
    margin: 0;
    font-size: .8rem;
    color: #333333;
}

.status-badge {
    color: #4CAF50;
    font-size: 1.5rem;
}

.title-line {
    border: 0;
    height: 1px;
    margin: 10px 0;
}

.meeting-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.detail-item {
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 0.9rem;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    height: auto;
    box-sizing: border-box;
    word-wrap: break-word;
}

.detail-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    background-color: #f0f4f8;
    flex-shrink: 0;
}

.detail-icon ion-icon {
    font-size: 1.25rem;
    color: #011584;
}

.detail-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 52px);
    overflow-wrap: break-word;
}

.detail-label {
    font-size: 0.75rem;
    color: #888888;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.detail-value {
    font-size: 0.95rem;
    color: #333333;
    font-weight: 500;
    word-wrap: break-word;
}

.status-box {
    background-color: #cceac1;
    border-radius: 5px;
    padding: 5px 5px;
    display: inline-block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.status-text {
    font-size: 0.8rem;
    color: #00796b;
    font-weight: 500;
}

.report-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    color: #000000;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.report-button ion-icon {
    font-size: 20px;
}

</style>